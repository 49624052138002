<template>
    <div class="flex justify-center">
        <div style="width: 95%;">
            <div class="flex items-center mt-10">
                <i class="el-icon-arrow-left font-bold text-xl cursor-pointer" @click="goBack" />
                <p class="text-xl ml-4 font-bold" style="color: #ff8e3b">EAE Pass Payment</p>
            </div>
            <div class="pay-info" style="width: 100%;padding: 15px 20px;box-sizing: border-box;margin-top: 25px;">
                <div class="flex justify-between font-bold text-lg">
                    <p>Pass</p>
                    <p style=" color: var(--c-primary);">{{ orderInfo.pass.name }}</p>
                </div>
                <div class="flex justify-between text-lg my-4">
                    <p>Standard Fee</p>
                    <p style="font-weight: bold;color: #ff8e3b;">USD {{ standard_fee
                        }}</p>
                </div>
                <div class="flex justify-between text-lg my-4">
                    <p>Applicable Fee Reduction</p>
                    <p style="font-weight: bold;color: #ff8e3b;">USD {{ calcReductionFee() }}</p>
                </div>
                <div class="flex justify-between text-lg my-4">
                    <p> Fee After Reduction</p>
                    <p style="font-weight: bold;color: #ff8e3b;">USD {{ orderInfo.total_price
                        }}</p>
                </div>
                <div class="flex justify-between text-lg my-4">
                    <p>Payment Service Fee</p>
                    <p style="font-weight: bold;color: #ff8e3b">USD {{ serviceFee }}</p>
                </div>

                <div style="width: 100%;height: 1px;background-color: var(--c-primary);"> </div>

                <div class="flex justify-between text-lg my-4 font-bold">
                    <p>Total Order Amount</p>
                    <p style="color: #ff8e3b">USD {{ amount }}</p>
                </div>
                <p style="font-style: italic;">*The service fee is charged by the payment platform. It is not
                    included in the registration fee.</p>
            </div>
            <div class="pay-info" style="width: 100%;padding: 15px 20px;box-sizing: border-box;margin-top: 25px;">
                <p class="text-lg font-bold">How We Calculate Your Fees</p>
                <div class="passdetail-content">
                    <div class="middle-content" style="width: 100%;">
                        <div style="display: flex; align-items: stretch; width: 100%; ">
                            <div style="flex: 4;">
                                <div class="price-container" style="display: flex;align-items: center;margin-left: 0px;">
                                    <p style="text-align: center; margin-left: 10px; display: flex; flex-direction: column;">
                                    <span style="color: var(--c-primary);">Standard<br>Fees</span>
                                    <span class="font-light" style="color: var(--c-primary);">{{standardPrice}} {{passPrice.currency}}</span>
                                    </p>
                                <div style="font-size: 24px; color: var(--c-primary); margin: 0 10px;">-</div>
                                
                                <p style="text-align: center;margin-left: 10px;">
                                    <span style="color: var(--c-primary);">Eligible Fee Reduction<br>for You</span>
                                    <span class="font-light" style="color: var(--c-primary);">{{reductionPrice}} {{passPrice.currency}}</span>
                                </p>
                                <div style="font-size: 24px; color: var(--c-primary); margin: 0 10px;">=</div>
                                <p style="text-align: center;margin-left: 10px;">
                                    <span style="color: var(--c-primary);">Fees You Need to Pay<br>after Reduction</span>
                                    <span class="font-light" style="color: var(--c-primary);">{{studentPrice}} {{passPrice.currency}}</span>
                                </p>

                                
                                
                            </div>
                            <div style="margin-top:20px;font-size:14px;font-family:centurygothic;line-height:150%">
                                    <p>1. The Fee Reduction Policy aims to support students by providing financial relief and ensuring that talent and merit are the key factors for success.</p>
                                    <p>2. Guided by principles of fairness and need-based support, the policy considers economic indicators and tuition fee structures to determine the level of reduction.</p>
                                    <p>3. A multi-tiered fee reduction policy is implemented with eight levels, based on a formula that considers Country/Region Class and Tuition Tier, while a registration fee covers operational costs.</p>

                                </div>
                            </div>
                            <div style="flex: 3; border-left: 1px solid #e0e0e0; padding-left: 20px; margin-left: 20px;">
                                <div class="fee-reduction-info">
                                    <h3 style="font-size: 18px; color: var(--c-primary); margin-bottom: 10px;">Your Fee Reduction Details</h3>
                                    <p><strong>Your Country Tier:</strong> Tier {{ levelResult.country_level }} ({{ levelResult.account_country }})</p>
                                    <p><strong>Your Tuition Tier:</strong> Tier {{ levelResult.finish_level }} ({{ tuition_tiers[levelResult.finish_level] }})</p>
                                    <p><strong>Your Fee Reduction Tier:</strong> Tier {{ levelResult.reduction_level }} ({{ reductionPrice }} {{ passPrice.currency }})</p>
                                </div>
                                <p class="title" style="margin: 10px 0px;">Reminder</p>
                        <div class="reminder-container" style="margin-bottom: 20px;">
                            <p class="reminder-content" style="font-size: 14px; font-family: centurygothic; line-height: 150%;">
                                If you notice that the applicable fee reduction is incorrect according to the fee reduction policy, please email us at <a href="mailto:contact@eae.online">contact@eae.online</a> for modification. For details on the specific fee reduction policy, refer to the information below.
                            </p>
                            <!--v-if="passInfo.BU == 'WAO'"-->
                            <div class="reminder-content">
                                <p style="margin-top: 10px;">
                                    <a href="https://ap.learningfirst.tech/EAE%20Fee%20Reduction%20Policy.pdf" target="_blank" style="color: #0000EE; text-decoration: underline; cursor: pointer;">Fee Reduction Policy</a>
                                </p>
                                <!-- <el-button type="primary" style="margin-top: 10px;" size="small" @click="gotoTierView('WAO')">Learn more </el-button> -->
                            </div>
                        </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>


            <!-- <div class="pay-info" style="width: 100%;padding: 15px 20px;box-sizing: border-box;margin-top: 25px;">
                <p class="text-lg font-bold">Reminder</p>
                <p style="margin-top: 10px;line-height: 24px;font-size: 16px;">
                    If you notice that the applicable fee reduction is incorrect according to the fee reduction policy, please email us at contact@eae.online for modification. For details on the specific fee reduction policy, refer to the information below.
                </p>
                <div v-if="orderInfo.pass.BU == 'WAO'" class="reminder-content">
                        <p style="margin-top: 10px;">
                            <a href="https://ap.learningfirst.tech/WAO/Resources/WAO%20Fee%20Reduction%20Policy%20240827.pdf" target="_blank" style="color: #0000EE; text-decoration: underline; cursor: pointer;">Fee Reduction Policy</a>
                        </p>
                    </div>
            </div> -->
            <div class="pay-info" style="width: 100%;padding: 15px 20px;box-sizing: border-box;margin-top: 25px;">
                <p class="text-xl font-bold">Terms and Conditions</p>
                <p class="ql-snow ql-editor" style="margin-top: 10px;line-height: 24px;font-size: 16px;"
                    v-html="refund_policy">
                </p>
            </div>
            <div class="terms flex items-center justify-center" style="margin-top: 20px;">
                <div @click="isAgree = !isAgree" style="display: flex; align-items: center">
                    <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-if="!isAgree"
                        :src="require('../../assets/image/unchecked.png')" />
                    <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-else
                        :src="require('../../assets/image/checked.png')" />
                </div>
                <p> I have read and agree on the terms and conditions.</p>
            </div>
            <div class="w-full flex justify-center items-center" style="margin: 30px 0px;">
                <el-button class="btn" @click="goBack">Cancel</el-button>
                <el-button class="btn" type="primary" @click="goPay">Pay</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {
    updatePassPaySuccess,
    getREPassOrder,
    postRequest,
    getPassPrice
} from '../../api/eae'
import {
    getUserId
} from '../../utils/store'
export default {
    name: "Payment",
    data() {
        return {
            passInfo:{},
            passPrice: {},
            studentPrice:  '',
            reductionPrice: '',
            standardPrice: '',
            levelResult: {},

            order_id: this.$route.query.order_id,
            isAgree: false,
            orderInfo: {},
            refund_policy: '',
            start_date: '',
            end_date: '',
            amount: 0,
            serviceFee: 0.00,
            standard_fee: 0,
            levelResult: {},
            tuition_tiers: {
                1: 'Exceeding 11,000 USD per year',
                2: 'Exceeding 6,000 USD per year',
                3: 'Exceeding 3,000 USD per year',
                4: 'Exceeding 1,500 USD per year',
                5: 'Exceeding 1,100 USD per year',
                6: 'Exceeding 750 USD per year',
                7: 'Exceeding 350 USD per year',
                8: 'Less than 350 USD per year but greater than 0',
                9: 'No tuition fees charged'
            },
        };
    },
    mounted() {
        this.fetchData()
    },

    methods: {

        fetchData() {
            getREPassOrder(this.order_id).then((res) => {
                this.orderInfo = res.data
                this.refund_policy = this.orderInfo.pass.refund_policy
                if (this.refund_policy && this.refund_policy != '') {
                    this.refund_policy = this.refund_policy.replaceAll('\r\n\r\n', '<br><br>')
                }
                this.passInfo = this.orderInfo.pass
                if (this.orderInfo.total_price == 0) {
                    this.orderInfo.service_fee = 0
                }
                this.serviceFee = this.orderInfo.service_fee
                this.standard_fee = this.orderInfo.standard_fee
                this.amount = this.orderInfo.service_fee + this.orderInfo.total_price

                this.requestPriceInfo(this.orderInfo.pass._id.$id)
            })
        },
       async requestPriceInfo(pass_id){
            let levelResult = await postRequest({
              function: "getStudentReductionLevelsByProject",
              student_id: getUserId()
            })
            this.levelResult = levelResult.data
            let studentLevel = this.levelResult.reduction_level

            let passPriceResult = await getPassPrice(pass_id)
            this.passPrice = passPriceResult.data
            this.standardPrice = this.passPrice.standard_price
            for (let i = 0; i < this.passPrice.prices.length; i++) {
                let item = this.passPrice.prices[i]
                if (item.level == studentLevel) {
                    this.studentPrice = item.price
                }
            }
            this.reductionPrice = this.passPrice.standard_price - this.studentPrice
        },
        calcReductionFee() {
            let fee = this.reductionPrice// this.standard_fee - (this.orderInfo.total_price)
            return '(' + fee + ')'
        },
        goBack() {
            this.$router.go(-1)
        },
        goPay() {

            if (!this.isAgree) {
                this.$message.warning("Please read terms and agree it");
                return;
            }
            if (this.orderInfo.status == "未支付" && this.orderInfo.total_price <= 0) {//此逻辑修复老数据
                updatePassPaySuccess(this.order_id).then((res) => {
                    this.$message.success('Pay success')
                    this.goBack()
                })
                return
            }
            if (this.orderInfo.status != "未支付") {
                this.goBack()
                return
            }
            let url = this.$router.resolve({
                path: "/pass-pay",
                query: {
                    order_id: this.order_id,
                },
            });
            window.open(url.href, "_blank");
        }
    }
};
</script>

<style lang="scss" scoped>

.passdetail-content {
    margin-top: 20px;
    width: 100%;
    // border: 1px solid #E6D7D7;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;

    .type-logo {
        width: 188px;
        height: 188px;
        object-fit: cover;
    }

    .middle-content {
        padding: 0px 10px;
        box-sizing: border-box;

        .price-container {
            height: 80px;
            border-radius: 5px;
            padding: 15px;
            box-sizing: border-box;
            color: var(--c-primary);
            font-size: 16px;
            line-height: 24px;
            font-family: centurygothic_bold;
            background-color: #FFF1F1;
            span{
              display: block;
            }
        }


    }

    .right-content {
        flex: 1;
        text-align: right;
        padding: 0px 10px;
        box-sizing: border-box;

        .btn-purchase {
            padding: 10px 25px;
            display: inline-block;
            cursor: pointer;
            text-align: center;
            background-color: var(--c-primary);
            color: white;
            border-radius: 5px;
            font-size: 20px;
            font-family: centurygothic_bold;
        }
        .btn-purchase-disabled {
            background-color: #e2e2e2;
        }
        .desc {
            color: #4A0700;
            font-size: 14px;
            font-family: centurygothic;
            line-height: 20px;
        }

    }
}


::-webkit-scrollbar {
    display: none;
}

.btn {
    width: 160px;
}

.terms {
    margin-top: 10px;
    width: 100%;
    background-color: #F4F4F4;
    padding: 20px 30px;
    box-sizing: border-box;
    color: #666;
    font-size: 16px;
    line-height: 24px;
}

.btn {
    width: 160px;
}

.pay-info {
    border-radius: 12px;
    background-color: #FFF8F8;
    color: #333;
    border: 1px solid #DEDEDE;
    padding: 20px 40px;
    box-sizing: border-box;
    margin-top: 30px;
}
</style>